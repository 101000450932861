/***************************************
**************************************

Style Sheet for the Homepage

**************************************
**************************************/

$mq-support: true;
$mq-fixed-value: 1024px;
@import "variables";
@import "mixins";

/********************************
Banner Area
*********************************/

#main{
	padding-bottom:0px;
}

.m-w2{
	max-width:none;

	@include bp(max-width,$bp-large){
		width:100%;
	}
}

#content{
	margin:0px;
	padding:0;
	max-width:none;
}

#banner{
	margin:0px;
	height:auto;

	img{
		top:-90px;
	}
}

.banner-content-overlay{
	height:770px;
	padding-top:135px; 
	@include bp(max-width,$bp-small){
		height:auto;
		padding:45px 20px 45px 20px;
		background:#333639 url(../img/home/banner-home-mobile.jpg) top center no-repeat;
		background-size:100% auto;
	}

	h1{
		font-size:30px;
		font-style:italic;
		color:#fff;
		margin-bottom:62px;

		@include bp(max-width,$bp-small){
			font-size:15px;
			margin-bottom:23px;
		}
	}

	> .sf_colsOut{
		max-width: 920px;
		margin:0 auto;
	}

	h2{
		font-size:56px;
		font-weight: 500;
		color:#fff;
		text-align: center;
		font-style:italic;
		@include bp(max-width,$bp-small){
			font-size:27px;
		}
	}

	.sfexpandedListWrp{
		h2{
			display: none;
		}
	}

	p{
		color:#fff;
		text-align: center;
		font-weight: 500;
		font-size:20px;
		font-style: italic;

		a{
			text-decoration: underline;
			display: block;
			margin:15px auto;
			color:#fff;
			max-width: 150px;
		}
	}
}


.home-menu .sflistList{
	border:1px solid #515557;
	background:#000000;
	background:rgba(0,0,0,.2);
	padding:12px;
	margin:0px;
	display: block;
	margin-bottom:200px;
	@include cf;
	@include bp(max-width,$bp-xtr-large){
		width:90%;
		margin:0 auto;
	}
	@include bp(max-width,$bp-small){
		margin-bottom:22px;
	}

	> li{
		list-style:none;
		float:left;
		width:19%;
		margin-right:1.0%;
		margin-bottom: 0px;
		position: relative;
		@include bp(max-width,$bp-small){
			float:none;
			width:100%;
			margin-bottom:12px;
		}


		&.open {
			h3{
				background:#fff;
				color:$prim-colour;

				&:after{
					@include bp(max-width,$bp-small){
						background-position:0 bottom;
					}
				}
			}

			.sfcontent{
				display: block;
				max-height: 300px;
			}
		}

		&:last-child{
			margin-right:0px;
		}

		&:nth-child(2) li:nth-child(1){
			@include bp(min-width,$bp-small){
				display: none;
			} 
		}
	}

	h3{
		color:#fff;
		background:$prim-colour;
		opacity: .9;
		padding:8px 0;
		font-size:20px;
		text-transform: uppercase;
		cursor:pointer;
		margin:0px;
		border:none;
		transition:all .5s; 
		position: relative;
		@include bp(max-width,$bp-small){
			opacity: 1;
		}

		&:after{
			display: none;
			@include bp(max-width,$bp-small){
				content: '';
			    display: block;
			    position: absolute;
			    left:auto;
			    right: 20px;
			    top: 14px;
			    margin:0px;
			    width: 20px;
			    height: 18px;
			    background: url(../img/bg/icon-close-orange.png) 0 0 no-repeat;
			    background-size: 17px 62px;
			    pointer-events:none;
			}  
		}
	}

	

	.sfcontent{ 
		overflow: hidden;
		max-height: 0;
		text-align: center;
		background:#fff url(../img/home/bg-home-drop.png) 0 0 repeat;
		position: absolute;
		left:0px;
		top:42px;
		width:100%;
		transition: all .5s;

		@include bp(max-width,$bp-small){
			position: static;
		}

		> a{
			
			padding:65px 10px 10px 10px; 
			display: block;
			text-decoration: none;
			font-size:16px;
			font-weight: 600;
			color:#000;
			font-style: italic;
			
			background-position:center 15px;
			background-repeat:no-repeat;

			span{
				color:$prim-colour;
				text-transform: uppercase;
			}
		} 

		.icon-park{background-image:url(../img/home/icon-find.png);}
		.icon-pay{background-image:url(../img/home/icon-pay.png);}
		.icon-apply{background-image:url(../img/home/icon-apply.png);}
		.icon-appeal{background-image:url(../img/home/icon-appeal.png);}
		.icon-buy{background-image:url(../img/home/icon-buy.png);}

	}

	ul{ 
		
		margin:0px;
 		  

		li {
			margin-bottom: 0px;
			list-style: none;

			a{
				font-size:14px;
				color:#000;
				font-style: italic;
				text-decoration: none;
				line-height: 1em;
				display: block;
				padding:10px 0;
				background:#fff;
				border-top:1px dotted #78797a;
				
				@include bp(max-width, $bp-small){
					font-size:15px;
					border-top:1px solid #78797a;
				}

				&:hover{
					color:$prim-colour;
				}
			}
		}
	}		
}

	
	


.second-level{
	background:url(../img/home/bg-home-second-level.jpg) top center no-repeat;
	height:1194px;

	@include bp(max-width,$bp-medium){
		height:auto;
		background:url(../img/home/bg-home-second-mobile.jpg) bottom center no-repeat;
		background-size:100% auto;
		padding-bottom:100vw;
	}

	> .sf_colsOut{
		max-width: 920px;
		margin:0 auto;
	}

	.second-level-text{
		padding:94px 50px 90px 50px;
		width:514px;
		height:400px;
		max-width: 50%;
		@include bp(max-width, $bp-large){
			padding-top:20px;
		}

		@include bp(max-width,$bp-medium){
			width:100%;
			max-width: 100%;
			height:auto;
			padding:45px 20px;
		}

		p{
			font-size:16px;
			line-height: 28px;
			color:#666666;
			margin-bottom:0px;
		}

		.highlight{
			color:$prim-colour;
		}
	}
}

.top-nav-btn{display:none;}


/******************************
Hides
*****************************/

#subnav{
	display:none;
}

/******************************
Getp app
*****************************/

// overwrite
.banner-content-overlay h2{
	@include bp(min-width,$bp-medium) {
		margin-top: 60px!important;
	}
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

// Block
.gs-getapp{
	display: none;


	@include bp(min-width,$bp-medium) {

		display: block;
		background: rgba(0, 0, 0, 0.41);
		border:1px solid rgba(255, 255, 255, 0.2);
		padding: 40px 30px 30px 210px;
		width: 460px;
		min-height: 184px;
		margin: auto;
		position: relative;
		overflow: hidden;
		animation-duration: 1s; 
		animation-delay: 1s;
		animation-fill-mode: both;
		animation-name: fadeIn;

		&:before{
			content:"";
			position: absolute;
			left: 0;
			bottom: -1px;
			width: 100%;
			height: 4px;
			background-color: #fc4c02;
			z-index: 10;
		}

		.gs-getapp_heading{
			font-size: 24px;
			font-weight: 500;
			font-style: italic;
			color: #fff;
			line-height: 1.25;
			margin-bottom: 15px;
		}

		.gs-getapp_graphic{
			position: absolute;
			left: 30px;
			bottom: 0;
			width: 154px;
			height: 152px;
			background: url(../img/getapp-graphic.png) no-repeat left top;
			z-index: 5;
			animation-duration: 1s;
			animation-delay: 1.5s;
			animation-fill-mode: both;
			animation-name: fadeInUp;
		}

		.gs-getapp_btn{
			text-decoration: none;
			display: inline-block;
			border-radius: 5px;
		}
			.gs-getapp_btn:hover{
				box-shadow: 0 4px 12px rgba(252, 76, 2, 0.12), 0 4px 12px rgba(252, 76, 2, 0.24);
	  			transition: all 0.3s cubic-bezier(.25,.8,.25,1);
			}
			.gs-getapp_btn+.gs-getapp_btn{
				margin-left: 3px;
			}
			.gs-getapp_btn img{
				vertical-align: middle;
				display: block;
			}
	}
}
	
	


.smartbanner-show {
	margin-top: 80px;
}

.smartbanner-show .smartbanner {
	display: block;
}
