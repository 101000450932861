/*
//---------------
//
//
*/

$mq-support: true !default;
$mq-fixed-value: $bp-large !default;

@mixin bp($feature, $value) {
    // Set global device param
    $media: only screen;

    // Media queries supported
    @if $mq-support == true {

        @media #{$media} and ($feature: $value) {
            @content;
        }

        // Media queries not supported
    } @else {

        @if $feature == 'min-width' {
            @if $value <= $mq-fixed-value {
                @content;
            }
        } 
        @else if $feature == 'max-width' {
            @if $value >= $mq-fixed-value {
                @content;
            }
        } 

    }
}
 

// Returns rem and px
// Note: Assumes html font size 62.5%
@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

@mixin border{
	border:1px solid $border-color;
}

@mixin cf{
    &:after {
      content: "";
      display: table;
      clear: both;
    }
}

@mixin btn{
    color:#fff;
    font-style:italic;
    font-size:16px;
    font-size:1.6rem;
    padding:3px 15px;
    border-radius:15px;
    cursor: pointer;
    text-decoration: none;
    text-align:center;
    background:$prim-colour;
    display:inline-block;
    margin-bottom:10px;
    border:none;

    @include bp(max-width, $bp-medium){
    padding:5px 15px;
    display: block;
    max-width: 400px;
    width:auto;
    }
    &:hover{
     color:#fff;
     background:#000;
    }
}


@mixin sectionInner{
    max-width: 1024px;
    margin:0 auto;
}

// Mobile Tabs (For App Landing and Product & Services Overview)

@mixin mobile_tabs{
    display:none;
    background:#a4a4a4;
    height:50px;
    margin:0 -20px;
    border-top:2px solid #fff;
    border-bottom:2px solid #fff;

    @include bp(max-width, $bp-medium){
        display: block;
    }

    ul{
        margin:0px;
        padding:0 20px;

        li{
            float:left;
            width:50%;
            height:50px; 
            list-style: none;
            margin:0px;
            padding:3px 0;

            a{
                display: block;
                text-align: center;
                border-radius:20px;
                color:#fff;
                font-size:16px;
                font-weight: 500;
                text-decoration: none;
                height:40px;
                line-height: 40px;

                &.active{
                    background:#fff;
                    color:#333333;
                }
            }
        }
    }
}




